import React from "react";
import { Navigate } from "react-router-dom";
import Auth from "storage/auth";
import TransactionStorage from "storage/transactionStorage";

const PrivateRoute = ({ children }) => {

    const is_transaction_route = () => {
        return window.location.pathname.includes('/transaction/')
    }
 
    const authenticated = Auth.getInstance().authenticated;

    if (authenticated != null && !authenticated) 
        return <Navigate to="/login" replace />;

    
    /*
    // redirect on transaction, page, if there some unsaved changes
    if(!is_transaction_route() && TransactionStorage.getInstance().hasOrder){
        try{
            const transaction = TransactionStorage.getInstance().getOrder
            return <Navigate to={`/transaction/${transaction.id}`} replace/>
        }catch(e){
            console.log(e)
        }
    }
    */
    return children;
};

export default PrivateRoute;