
import axiosInstance, { baseURL } from './axiosConfig'

export const sanctumConfig = {
    apiUrl: baseURL,
    apiTarget: '/api',
    csrfCookieRoute: "sanctum/csrf-cookie",
    signInRoute: "login",
    signOutRoute: "logout",
    forgotPasswordRoute: "forgot-password",
    resetPasswordRoute: "reset-password",
    userObjectRoute: "api/user",
    storageURL: `${baseURL}/storage`,
    axiosInstance: axiosInstance,
    getFilePath: (file: string) => {
        return `${baseURL}/storage/${file.split('public/')[1]}`
    }
};
