import React from 'react';

import { Sanctum } from "react-sanctum";
import { sanctumConfig } from "config/sanctum"

import PrivateRoute from 'config/privateRoute';
import PublicRoute from 'config/publicRoute';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import 'App.css';

const Home = React.lazy(() => import('pages/Home'));
const Login = React.lazy(() => import('pages/auth/Login'));
const ForgotPassword = React.lazy(() => import('pages/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/auth/ResetPassword'));
const OrderList = React.lazy(() => import('pages/order/OrderList'));
const OrderDetail = React.lazy(() => import('pages/order/OrderDetail'));
const Transaction = React.lazy(() => import('pages/transactions'));
const Settings = React.lazy(() => import('pages/Settings'));


function App() {
  return (
	<Sanctum config={sanctumConfig}>
		<BrowserRouter>
			<React.Suspense fallback={''}>
				<Routes>
					<Route path="/login" element={<PublicRoute><Login /></PublicRoute> } />
					<Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute> } />
					<Route path="/reset-password/:token" element={<PublicRoute><ResetPassword /></PublicRoute> } />
					
					<Route path="/" element={<PrivateRoute><Home /></PrivateRoute> } />
					<Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute> } />
					<Route path="/order" element={<PrivateRoute><OrderList /></PrivateRoute> } />
					<Route path="/order/:order_id" element={<PrivateRoute><OrderDetail /></PrivateRoute> } />
					<Route path="/transaction/:order_id" element={<PrivateRoute><Transaction /></PrivateRoute> } />
				</Routes>
			</React.Suspense>
		</BrowserRouter>
	</Sanctum>
  );
}

export default App;
