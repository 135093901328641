import { useSanctum } from "react-sanctum";

function SanctumInstance(){
    const { user, authenticated } = useSanctum();
    return { user, authenticated };
}

class Auth {
    private static instance: Auth;
    private static storage: any;

    private static keyName = 'user';

    private constructor() { 
        Auth.storage = sessionStorage
    }

    public static getInstance(): Auth {
        if (!Auth.instance) 
            Auth.instance = new Auth();
        return Auth.instance;
    }

    public get getUser() {
        if(!Auth.storage.getItem(Auth.keyName))
            return SanctumInstance().user
        return JSON.parse(Auth.storage.getItem(Auth.keyName));
    }

    public setUser(userData: Object) {
        Auth.storage.setItem(Auth.keyName, JSON.stringify(userData))
    }

    public delUser() {
        Auth.storage.removeItem(Auth.keyName)
        // SanctumInstance().setUser({})
    }

    public get authenticated() {
        const status = !["undefined", null].includes(Auth.storage.getItem(Auth.keyName)) || SanctumInstance().authenticated
        return status || false
    }
}

export default Auth;
