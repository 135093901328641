import React from "react";
import { Navigate } from "react-router-dom";
import Auth from "storage/auth";

const PublicRoute = ({ children }) => {
    const authenticated = Auth.getInstance().authenticated;
    if (authenticated != null && authenticated) 
        return <Navigate to="/" replace />;

    return children;
};

export default PublicRoute;